
import { Component, Watch } from 'vue-property-decorator'
import { debounce } from 'lodash'
import moment from 'moment'
import VueStrong from '@/VueStrong'

@Component
export default class FiltersMixin extends VueStrong {
  filtersType = '' // 'campaign' | 'adset' | 'ad'
  paramsField = ''
  setFilterAction = ''
  getAction = ''
  updateAction = ''

  date = ''
  dates = []
  modal = false

  debounceGetData = debounce(this.getData, 500)
  debounceReloadInsights = debounce(this.reloadInsights, 500)

  initialParams = {}

  get params() {
    return this.$store.state.insight ? this.$store.state.insight[this.paramsField] || {} : {}
  }

  get parentId() {
    if (this.filtersType === 'adset')
      return this.$store.state.insight ? this.$store.state.insight.selectedCampaignId || '' : ''
    if (this.filtersType === 'ad')
      return this.$store.state.insight ? this.$store.state.insight.selectedAdSetId || '' : ''
    return ''
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  mounted() {
    const data = [this.params.start, this.params.end].sort((a, b) => moment(a).diff(moment(b)))
    this.date = data.join(' ~ ')
  }

  async updateParams(newParams: any): Promise<void> {
    this.$store.dispatch(this.setFilterAction, newParams)
    this.debounceGetData()
  }

  formatDate(date: moment.Moment) {
    return date.format('YYYY-MM-DD')
  }

  async updateDateFilter(data: string[]) {
    data = data.sort((a, b) => moment(a).diff(moment(b)))
    const start = data[0]
    const end = data[1]
    this.date = data.join(' ~ ')
    await this.updateParams({ ...this.params, start, end })
  }

  async getData() {
    this.$emit('setLoading', true)
    try {
      await this.$store.dispatch(this.getAction)
    } catch (error) {
      this.$emit('errorNotification', true)
    }
    this.$emit('setLoading', false)
  }

  async reloadInsights() {
    this.$emit('setLoading', true)

    try {
      await this.$store.dispatch(this.updateAction, this.parentId)
    } catch (error) {
      this.$emit('errorNotification', true)
    }

    this.$emit('setLoading', false)
    this.debounceGetData()
  }

  @Watch('selectedAdAccount')
  onAdAccountChange() {
    this.updateParams({ ...this.params, ...this.initialParams })
  }
}
